import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useStyles} from './LogWorkTimeStyles'
import Form, {Field} from '@atlaskit/form'
import {Box, Grid, Link, Typography} from '@material-ui/core'
import TextArea from '@atlaskit/textarea'
import {LogWorkTimeProperties} from '../../types/LogWorkTimeProperties'
import Button from '@atlaskit/button'
import {
    getBoardMembers,
    getCurrentMember,
    removeTimer,
    storeTimeEntry,
    updateTimeEntry
} from '../../modules/Persistence'
import Member from 'trello-shared-resources/dist/types/Member'
import {LogWorkInput, LogWorkInputState, SelectOptionValue, WorkLogStored} from '../../types/LogProperties'
import Card from 'trello-shared-resources/dist/types/Card'
import Spinner from '@atlaskit/spinner'
import {MessageType} from '../../error/FetchDataError'
import {supportUrl, TrackActionEvent} from 'trello-shared-resources/dist'
import {
    canCurrentUserPerformActions,
    convertSecondsToTimeDuration,
    convertSecondsToWorklogDuration,
    dateToString,
    getMemberName
} from '../../modules/Utils'
import CrossIcon from '@atlaskit/icon/glyph/cross'
import {DateAndTimeWorklogFields} from './DateAndTimeWorklogFields'
import {ListCardAndMemberWorklogFields} from './ListCardAndMemberWorklogFields'
import CancelModal from '../common/CancelModal'
import {ReportDataMessage} from '../../types/ReportProperties'
import ReportDisplayMessages from '../report/ReportDisplayMessages'

const LogWorkTime = (props: LogWorkTimeProperties) => {

    const classes = useStyles()

    const {worklog, setReportDataMessage, setIsWorklogModalOpen, licenseDetails} = props
    const workLogKey = worklog?.key
    const logWorkFromCard = props.logWorkFromCard || false
    const editWorklogFromCard = props.editWorklogFromCard || false

    const [listOptions, setListOptions] = useState<SelectOptionValue[]>([])
    const [listSelected, setListSelected] = useState<SelectOptionValue | undefined>(undefined)
    const [cardOptions, setCardOptions] = useState<SelectOptionValue[]>([])
    const [cardSelected, setCardSelected] = useState<SelectOptionValue | undefined>(worklog?.card ? {
        label: worklog.card.name!,
        value: worklog.card.id
    } : undefined)
    const [cardsSelectedInList, setCardsSelectedInList] = useState<Array<any>>([])
    const [memberSelected, setMemberSelected] = useState<SelectOptionValue | undefined>(worklog?.member ? {
        label: getMemberName(worklog.member),
        value: worklog.member.username
    } : undefined)
    const [memberOptions, setMemberOptions] = useState<SelectOptionValue[]>([])
    const [cards, setCards] = useState<Card[]>([])
    const [members, setMembers] = useState<Member[]>()
    const [currentMember, setCurrentMember] = useState<Member | undefined>()
    const [isLoadingStoring, setIsLoadingStoring] = useState<boolean>(false)
    const [isLoadingData, setIsLoadingData] = useState<boolean>(true)
    const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false)
    const [isCloseModal, setIsCloseModal] = useState<boolean>(false)
    const [startDateValue, setStartDateValue] = useState<string>(worklog?.rawStartDate ? dateToString(new Date(worklog.rawStartDate), worklog.userTimezone) : '')
    const [endDateValue, setEndDateValue] = useState<string>(worklog?.rawEndDate ? dateToString(new Date(worklog.rawEndDate), worklog.userTimezone) : '')
    const [startTimeValue, setStartTimeValue] = useState<string>(worklog?.startTime ? convertSecondsToTimeDuration(worklog.startTime) : '')
    const [endTimeValue, setEndTimeValue] = useState<string>(worklog?.endTime ? convertSecondsToTimeDuration(worklog.endTime) : '')
    const [totalTimeValue, setTotalTimeValue] = useState<string>(worklog?.loggedHours ? convertSecondsToWorklogDuration(worklog.loggedHours) : '')
    const [isBillable] = useState<boolean>(worklog?.billable || false)
    const [formError, setFormError] = useState<ReportDataMessage | undefined>(undefined)

    const trelloIframeContext = licenseDetails.trelloIframeContext
    const memberRef = useRef<any>()
    const descRef = useRef<any>()
    const resetFormRef = useRef<any | undefined>()

    /**
     * Submit Form action
     * @param logWorkTimeForm fields values form
     */
    const formSubmitHandler = async (logWorkTimeForm: LogWorkInputState) => {
        setIsLoadingStoring(true)
        const card = cards.find(card => card.id === (logWorkTimeForm.card?.value || worklog?.card?.id))
        const member = members!.find(member => member.username === logWorkTimeForm.member.value)

        if (worklog && workLogKey && !canCurrentUserPerformActions(worklog, currentMember)) {
            setReportDataMessage({
                title: '',
                subTitle: 'You can not edit time logs that belongs to other users.',
                messageType: MessageType.error,
                closable: true
            })
            setIsWorklogModalOpen(false)
            return
        }

        if (!card || !member) {
            await displayErrorAndCloseModal()
            return
        }

        const logWorkInput: LogWorkInput = {
            ...logWorkTimeForm, card, member,
            currentMember,
            worklogKey: workLogKey,
            startDate: logWorkTimeForm.startDate || startDateValue,
            endDate: logWorkTimeForm.endDate || endDateValue,
            startTime: logWorkTimeForm.startTime || startTimeValue,
            endTime: logWorkTimeForm.endTime || endTimeValue,
            totalTime: logWorkTimeForm.totalTime || totalTimeValue,
            billable: logWorkTimeForm.billable
        }

        if (worklog?.key) {
            updateTimeEntry(trelloIframeContext, worklog.key, logWorkInput)
                .then(async () => await processSuccessWorklogOperation(logWorkInput))
                .catch(async () => await displayErrorAndCloseModal())
        } else {
            storeTimeEntry(trelloIframeContext, logWorkInput)
                .then(async (worklogStored: WorkLogStored) => {
                    if(props.logFromTimer) {
                        await removeTimer(licenseDetails)
                        if(props.setLogFromTimer) props.setLogFromTimer(false)
                    }
                    await processSuccessWorklogOperation({...logWorkInput, worklogKey: worklogStored.hashKey})
                })
                .catch(async () => await displayErrorAndCloseModal())

        }
    }

    /**
     * If worklog creation / update has been successfully, then:
     *      1. Track that event
     *      2. Show a success message
     *      3. Refresh worklogs list
     * @param logWorkInput the info filled by the user
     */
    const processSuccessWorklogOperation = (logWorkInput: LogWorkInput) => {
        const trelloContextInfo = trelloIframeContext.getContext()
        TrackActionEvent('Log Work', trelloContextInfo, {
            board_id: trelloContextInfo.board,
            card_id: logWorkInput.card.id,
            time: logWorkInput.totalTime,
            action: workLogKey ? 'update' : 'add',
            description: logWorkInput.workDescription !== undefined && logWorkInput.workDescription !== '',
            billable: logWorkInput.billable
        })

        if(editWorklogFromCard && workLogKey && logWorkInput.card.id !== trelloContextInfo.card) {
            setReportDataMessage({
                title: '',
                subTitle: 'The timelog has been moved to the card you selected.',
                messageType: MessageType.info,
                closable: true
            })
        } else {
            setReportDataMessage({
                title: '',
                subTitle: workLogKey ? 'You have successfully edited the time log.' : 'You have successfully logged time.',
                messageType: MessageType.success,
                closable: true
            })
        }
        setIsWorklogModalOpen(false)

        props.setCreatedOrEditedRow?.(logWorkInput.worklogKey)
        props.incrementActionPerformed?.()
        if(logWorkFromCard) resetForm()
        setIsLoadingStoring(false)
    }

    const displayErrorAndCloseModal = useCallback(async () => {
        if(workLogKey) {
            setFormError({
                title: '',
                subTitle: <>
                    Sorry! There was a problem updating your time log.
                    Please try again, if the issue persists, please contact our Support team <Link href={supportUrl}
                                                                                                   target="_blank">here</Link>.
                </>,
                messageType: MessageType.error,
                closable: true
            })
        } else {
            setReportDataMessage({
                title: '',
                subTitle: <>
                    Sorry! There was a problem creating your time log.
                    Please try again, if the issue persists, please contact our Support team <Link href={supportUrl}
                                                                                               target="_blank">here</Link>.
                </>,
                messageType: MessageType.error,
                closable: true
            })
            setIsWorklogModalOpen(false)
            setIsLoadingStoring(false)
        }
    }, [workLogKey, setReportDataMessage, setIsWorklogModalOpen])

    /**
     * Update card's list by the given list ID
     * @param listId the selected list ID
     * @param listAndCards array that contains an object with the lists + cards
     */
    const setCardsByListId = (listId: string, listAndCards: Array<any>) => {
        const listSelected: any = listAndCards.find((listCard: any) => listCard.id === listId)
        if (listSelected) {
            const cardsInList = listSelected.cards.map((card: any) => ({label: card.name, value: card.id}))
            setCardOptions(cardsInList)
        }
    }

    /**
     * Load init values
     */
    const loadValues = useCallback(async () => {
        const lists: Array<any> = await trelloIframeContext.lists('all')
        const boardMembers: Array<Member> = await getBoardMembers(trelloIframeContext)

        if (!boardMembers || boardMembers.length === 0) {
            await displayErrorAndCloseModal()
            return
        }
        setMembers(boardMembers)
        setMemberOptions(boardMembers.map((member: Member) => ({
            label: getMemberName(member),
            value: member.username
        })))

        const listAndCards = lists.map((list: any) =>
            ({
                ...list,
                cards: list.cards
            })
        )
        if (listAndCards.length > 0) {
            setListOptions(listAndCards.map((list: any) => ({
                label: list.name,
                value: list.id,
                isDisabled: list.cards && list.cards.length === 0
            })))
            setCards(listAndCards.map((list: any) => list.cards).flat())
            setCardsSelectedInList(listAndCards)
        }
        if (worklog && worklog.listId) {
            const listSelected = lists.find((list: any) => list.id === worklog.listId)
            setListSelected(listSelected ? {label: listSelected.name, value: listSelected.id} : undefined)
            setCardsByListId(worklog.listId, listAndCards)
        }
        setIsLoadingData(false)
    }, [displayErrorAndCloseModal, trelloIframeContext, worklog])

    const loadCurrentMember = useCallback(async () => {
        getCurrentMember(trelloIframeContext).then((member) => {
            setCurrentMember(member)
        })
    }, [trelloIframeContext])


    useEffect(() => {
        if (listOptions.length === 0) {
            loadValues()
            loadCurrentMember()
        }
    }, [loadValues, loadCurrentMember, listOptions.length])

    if (isLoadingData) {
        return (<Grid container spacing={0} alignItems="center" justifyContent="center" direction="row">
            <Grid item>
                <Spinner size={200}/>
            </Grid>
        </Grid>)
    }

    const resetForm = () => {
        setEndDateValue('')
        setEndTimeValue('')
        setStartDateValue('')
        setStartTimeValue('')
        setTotalTimeValue('')
        memberRef.current.select.select.clearValue()
        descRef.current.value = ''
        if(resetFormRef.current) resetFormRef.current()
    }

    return (<Form onSubmit={formSubmitHandler}>
            {({formProps, reset}) => {
                resetFormRef.current = reset
                return (
                    <form {...formProps} id="LogWorkTimeForm">
                        <Grid container className={classes.container} alignItems="flex-start">
                            <Grid item xs={12}>
                                {!logWorkFromCard &&
                                    <Box className={classes.crossIcon}
                                         onClick={() => {
                                             setIsCancelModalOpen(true)
                                             setIsCloseModal(true)
                                         }}>
                                        <CrossIcon label="close" size="medium"/>
                                    </Box>
                                }
                                <Typography
                                    className={classes.title}>{workLogKey ? 'Edit Logged Time' : 'Log Time'}</Typography>
                            </Grid>
                            {formError && <Grid item xs={12} className={classes.messageContainer}>
                                <Box>
                                    <ReportDisplayMessages reportDataMessage={formError} setReportDataMessage={setFormError}/>
                                </Box>
                            </Grid>}
                            <ListCardAndMemberWorklogFields
                                listOptions={listOptions} cardOptions={cardOptions}
                                logWorkFromCard={logWorkFromCard}
                                cardSelected={cardSelected} setCardSelected={setCardSelected}
                                cardsSelectedInList={cardsSelectedInList} memberOptions={memberOptions}
                                setCardsByListId={setCardsByListId}
                                memberSelected={memberSelected} setMemberSelected={setMemberSelected}
                                listSelected={listSelected}
                                memberRef={memberRef}
                                setListSelected={setListSelected}
                            />
                            <DateAndTimeWorklogFields startDateValue={startDateValue} setStartDateValue={setStartDateValue}
                                                      endDateValue={endDateValue} setEndDateValue={setEndDateValue}
                                                      startTimeValue={startTimeValue} setStartTimeValue={setStartTimeValue}
                                                      endTimeValue={endTimeValue} setEndTimeValue={setEndTimeValue}
                                                      totalTimeValue={totalTimeValue} setTotalTimeValue={setTotalTimeValue}
                                                      billable={isBillable}
                            />
                            <Grid container className={classes.row}>
                                <Grid item xs={12}>
                                    <Field label="Work Description" name="workDescription"
                                           defaultValue={worklog?.workDescription || ''}>
                                        {({fieldProps}: any) => (
                                            <TextArea className={classes.workDescription} {...fieldProps} ref={descRef}/>
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>
                            <Grid container className={classes.rowButtons} spacing={1} direction="row"
                                  justifyContent="flex-end"
                                  alignItems="baseline">
                                {isLoadingStoring &&
                                    <Grid item className={classes.loadingSpinner}>
                                        <Spinner size="medium"/>
                                    </Grid>
                                }
                                <Grid item>
                                    <Button appearance="default" className={classes.cancelButton}
                                            onClick={() => {
                                                setIsCloseModal(false)
                                                setIsCancelModalOpen(true)
                                            }}>
                                        Cancel
                                    </Button>
                                    <Button type="submit" appearance="primary"
                                            isDisabled={isLoadingStoring}>{workLogKey ? 'Update' : 'Log Time'}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <CancelModal isCancelModalOpen={isCancelModalOpen} setIsCancelModalOpen={setIsCancelModalOpen}
                                     okButtonText={isCloseModal ? 'Yes, Close' : ''}
                                     titleText={isCloseModal ? 'Confirm close' : ''}
                                     mainBodyText={isCloseModal ? 'Are you sure you want to close? You’ll lose the work you’ve done so far.' : ''}
                                     closeAction={() => setIsCancelModalOpen(false)}
                                     cancelAction={() => setIsCancelModalOpen(false)}
                                     confirmAction={() => {
                                         resetForm()
                                         setIsCloseModal(false)
                                         props.setIsWorklogModalOpen(false)
                                         if (logWorkFromCard) trelloIframeContext.closeModal()
                                     }}/>
                    </form>
                )
            }}
        </Form>
    )
}

export default LogWorkTime